.pageNotFound {
  padding: 270px 0 150px 0;
  margin: 0 auto;
  width: fit-content;
}
.number {
  text-align: center;
  font-size: 200px;
}
.info {
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 68px;
  max-width: 620px;
  display: block;
}
.buttonBack {
  margin: 0 auto;
  display: block;
  width: fit-content;
}
@media screen and (max-width: 768px) {
  .pageNotFound {
    padding: 200px 0 128px 0;
  }
  .number {
    font-size: 100px;
  }
  .info {
    padding: 0 32px;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }
}
