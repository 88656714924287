@import '@/styles/styles';

.btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: $text-color-primary-dark;
  border: none;
  border-radius: 100px;
  color: $white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  padding: 10px 32px;
  transition: background-color 0.3s ease;
  font-family: inherit;

  &:hover {
    background-color: lighten($text-color-primary-dark, 20%);
  }
}
